.MdManual {
    height: 100%;
    overflow: auto;
    padding: 10px;
    border-radius: 5px;
    user-select: text;
    border-radius: inherit;
    color: #444;
}
.MdManual A {
    color: rgb(0, 105, 192);
}

.MdManual H1 {
    margin: 0;
    font-size: 20px;
    font-weight: normal;
    color: black;
}

.MdManual H2 {
    margin: 0;
    font-size: 18px;
    font-weight: normal;
    color: black;
}

.MdManual H3 {
    margin: 0;
    font-size: 17px;
    font-weight: normal;
    color: black;
}

.MdManual CODE {
    background: #e1f5fe;
    display: inline-flex;
    padding: 15px;
    margin-left: 10px;
    border-radius: 5px;
    border: solid 2px #000;
    color: black;
    font-family: "IBM Plex Mono";
    width: 100%;
    overflow: auto;
}