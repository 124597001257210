.ErrorsBox {
    border: solid 2px #d81b60;
    padding: 15px;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    color: #fff;
    background-color: #d81b60;
    width: 100%;
}

.NotFullErrorBox {
    border-radius: 4px;
}

.ErrorsBox > .ErrorDetails {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.ErrorsBox .ErrorDetails > .Header {
    font-size: 120%;
    text-decoration: underline;
    font-weight: 400;
    margin-left: 15px;
}
