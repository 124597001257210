.Tabs {
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    border-radius: inherit;
}

.Tabs > .Navigation {
    -webkit-box-shadow: 0px 8px 14px -13px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 8px 14px -13px rgba(66, 68, 90, 1);
    box-shadow: 0px 8px 14px -13px rgba(66, 68, 90, 1);
    display: flex;
    height: 45px;
    z-index: 10;
    position: sticky;
    top: 0;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    padding-left: 20px;
}

.Tabs > .Navigation > .Tab {
    padding: 13px 10px 10px 10px;
    flex: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
}

.Tabs .TabContent {
    height: inherit;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: inherit;
}