 SPAN.Button {
    display: flex;
    align-items: center;
    padding: 6px 9px;
    margin: 2px;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
    user-select: none;
    background-color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
     background-position: center;
     transition: background 0.8s;
 }

 SPAN.Button:hover {
     color: white;
     border: solid 2px #006db3;
     background: #006db3 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
 }

 SPAN.Button:active {
     background-color: #006db3;
     background-size: 100%;
     transition: background 0s;
 }

 SPAN.EnabledButton {
    border: solid 2px white;
    color: #006db3;
}

 SPAN.DisabledButton {
    border: solid 2px #888;
    color: #888;
}
 SPAN.ConfirmedButton {
    border: solid 2px #43a047;
    background-color: #43a047;
    color: whitesmoke;
}
 SPAN.ErrorButton {
    border: solid 2px #d81b60;
    background-color: #d81b60;
    color: whitesmoke;
}
 SPAN.ButtonSelected {
    background-color: #006db3;
    color: white;
}

 SPAN.EnabledButton:hover {
    -webkit-box-shadow: 0 0 8px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0 0 8px -1px rgba(0,0,0,0.75);
    box-shadow: 0 0 8px -1px rgba(0,0,0,0.75);
}